@use "sass:color";

// paper & background
$paper: #ffffff;

// primary
$primaryLight: #4A8DB3;
$primaryMain: #156691; // Your main color
$primaryDark: #0f4d7d; // Your dark version
$primary200: #90caf9;
$primary800: #1565c0;

// Secondary
$secondaryMain: #e7cdbf; // Lighten the primary color
$secondaryLight: color.adjust($secondaryMain, $lightness: 10%); // Lighten the primary color
$secondaryDark: color.adjust($secondaryMain, $lightness: -20%); // Darken the primary color
$secondary200: color.adjust($secondaryMain, $lightness: 30%); // Lighten the primary color
$secondary800: color.adjust($secondaryMain, $lightness: -20%); // Darken the primary color

$successLight: #7fdb9d;
$success200: #34d058;
$successMain: #28a745;
$successDark: #218838;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #f4883d;
$orangeMain: #f4883d;
$orangeDark: #f4883d;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

$darkWarningLight: #e0d6b9;
$darkWarningMain: #ccbf72;
$darkWarningDark: #b38f07;

// grey
$grey50: #f8fafc;
$grey100: #eef2f6;
$grey200: #e3e8ef;
$grey300: #cdd5df;
$grey400: #cdd5df;
$grey500: #697586;
$grey600: #4b5565;
$grey700: #364152;
$grey900: #121926;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #000000; // black
$darkPaper: #111111; // slightly lighter black

// dark 800 & 900
$darkLevel1: #1a1a1a; // slightly lighter black (level 1)
$darkLevel2: #121212; // even lighter black (level 2)

// primary dark
$darkPrimaryLight: #eef2f6;
$darkPrimaryMain: #136691;
$darkPrimaryDark: #136691;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

// secondary dark
$darkSecondaryLight: color.adjust($secondaryMain, $lightness: -50%);
$darkSecondaryMain: color.adjust($secondaryMain, $lightness: -50%);
$darkSecondaryDark: color.adjust($secondaryMain, $lightness: -60%);
$darkSecondary200: color.adjust($secondaryMain, $lightness: -60%);
$darkSecondary800: color.adjust($secondaryMain, $lightness: -60%);

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey400: $grey400;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  darkWarningLight: $darkWarningLight;
  darkWarningMain: $darkWarningMain;
  darkWarningDark: $darkWarningDark ;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;
}